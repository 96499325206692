<template>
  <div id="wrapper">
    <div id="loading-wrapper" v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('COMPANY')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- name text input -->
          <b-form-group
              id="name-form-group"
              :label="$t('COMPANY_NAME_LABEL') | capitalize"
              label-for="name-input"
              :state="state('name')">
            <b-form-input class="w-100 ml-1" id="name-input" type="text"
                          v-model="$v.form.name.$model"
                          :state="state('name')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY_NAME_LABEL')},})  | capitalize  }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- name text input -->

          <!-- rfc text input -->
          <b-form-group
              id="rfc-form-group"
              :label="$t('ORGANIZATION_RFC_LABEL') | capitalize"
              label-for="rfc-input"
              :state="state('rfc')">
            <b-form-input class="w-100 ml-1" id="rfc-input" type="text"
                          v-model="$v.form.rfc.$model"
                          :state="state('rfc')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="rfc-input-feedback">
              <div v-for="error in errors('rfc')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ORGANIZATION_RFC_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- rfc text input -->

          <!-- email input -->
          <b-form-group
              class="w-100 d-inline-block"
              id="email-form-group"
              :label="$t('USER_EMAIL_LABEL') | capitalize"
              label-for="email-input"
              :state="state('email')"
          >
            <b-form-input id="email-input"
                          class="w-100 ml-1"
                          :value="form.email"
                          @change="set('email', $event)"
                          :state="state('email')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="email-input-feedback">
              <div v-for="error in errors('email')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_EMAIL_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- email input -->

          <!-- type text input -->
          <b-form-group
              id="type-form-group"
              :label="$t('COMPANY_TYPE_LABEL') | capitalize"
              label-for="type-input"
              :state="state('type')">
            <b-form-select class="w-100 ml-1" id="type-input" type="text"
                          v-model="$v.form.type.$model"
                          :state="state('type')"
                          trim>
              <b-select-option value="DONOR">{{$t('COMPANY_TYPE_DONOR_LABEL')}}</b-select-option>
              <b-select-option value="SUPPLIER">{{$t('COMPANY_TYPE_SUPPLIER_LABEL')}}</b-select-option>
              <b-select-option value="DONOR_SUPPLIER">{{$t('COMPANY_TYPE_DONOR_SUPPLIER_LABEL')}}</b-select-option>
            </b-form-select>

            <b-form-invalid-feedback id="type-input-feedback">
              <div v-for="error in errors('type')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('COMPANY_TYPE_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- type text input -->

          <!-- bank accounts -->
          <h3>{{$t('FORMS_BANK_ACCOUNT_TITLE') | capitalize}}</h3>
          <b-button @click="addAccount">{{$t('FORMS_BANK_ACCOUNTS_ADD') | capitalize}}</b-button>

          <div class="d-inline-block w-100 my-1" :key="index" v-for="(v, index) in $v.form.accounts.$each.$iter">
            <div class="d-inline-block w-50">
              <b-form-group
                  class="w-25 pr-1 d-inline-block"
                  id="bank-account-code-form-group"
                  :label="$t('BANK_ACCOUNT_CODE_LABEL') | capitalize"
                  label-for="bank-account-code-input"
                  :state="state2(v, 'code')">
                <b-form-input class="w-100 ml-1" id="type-input" type="text"
                              v-model="v.code.$model"
                              :state="state2(v, 'code')"
                              trim></b-form-input>

                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'code')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BANK_ACCOUNT_CODE_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  class="w-75 pr-1 d-inline-block"
                  id="bank-account-description-form-group"
                  :label="$t('BANK_ACCOUNT_DESCRIPTION_LABEL') | capitalize"
                  label-for="bank-account-description-input"
                  :state="state2(v, 'description')">
                <b-form-input class="w-100 ml-1" id="type-input" type="text"
                              v-model="v.description.$model"
                              :state="state2(v, 'description')"
                              trim></b-form-input>

                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'description')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BANK_ACCOUNT_DESCRIPTION_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="d-inline-block w-50">
              <b-form-group
                  v-if="form.type !== 'DONOR'"
                  class="w-25 pr-1 d-inline-block"
                  id="bank-account-account-form-group"
                  :label="$t('BANK_ACCOUNT_ACCOUNT_LABEL') | capitalize"
                  label-for="bank-account-account-input"
                  :state="state2(v, 'account')">
                <b-form-input class="w-100 ml-1" id="type-input" type="text"
                              v-model="v.account.$model"
                              :state="state2(v, 'account')"
                              trim></b-form-input>

                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'account')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BANK_ACCOUNT_ACCOUNT_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  v-if="form.type !== 'DONOR'"
                  class="w-25 pr-1 d-inline-block"
                  id="bank-account-business-segment-form-group"
                  :label="$t('BANK_ACCOUNT_BUSINESS_SEGMENT_LABEL') | capitalize"
                  label-for="bank-account-business-segment-input"
                  :state="state2(v, 'businessSegment')">
                <b-form-input class="w-100 ml-1" id="type-input" type="text"
                              v-model="v.businessSegment.$model"
                              :state="state2(v, 'businessSegment')"
                              trim></b-form-input>

                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'businessSegment')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BANK_ACCOUNT_BUSINESS_SEGMENT_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group v-if="form.type !== 'DONOR'" class="d-inline-block pl-1 pb-1 w-25 pr-1" :label="$t('BANK_ACCOUNT_ORGANIZATION_LABEL') | capitalize" :state="state2(v, 'organizationId')">
                <organization-single-selector valueType="id" v-model="v.organizationId.$model"></organization-single-selector>

                <b-form-invalid-feedback id="type-input-feedback">
                  <div v-for="error in errors2(v, 'organizationId')" :key="error.error">
                    {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BANK_ACCOUNT_ORGANIZATION_LABEL')},})  | capitalize }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="d-inline-block w-10 pl-1" style="vertical-align: middle">
                <b-button @click="removeAccount(index)" variant="danger">
                  <b-icon-x></b-icon-x>
                </b-button>
              </div>
            </div>

          </div>
          <!-- bank accounts -->

          <div>
            <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
              <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
              <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
              <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
            </b-button>
          </div>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {required, minLength, helpers, maxLength, email} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Company from "@/company";
import Constants from "@/constants";
import OrganizationSingleSelector from "@/organization/OrganizationSingleSelector";

const regex = helpers.regex('regex', /^([A-ZÑ&]{3,4}) ?(?:- ?)?(d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]d|3[01])) ?(?:- ?)?([A-Zd]{2})([Ad])$/);

export default {
  name: "CompanyForm",
  components: {OrganizationSingleSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    addAccount() {
      this.$v.form.accounts.$model.push({code: null, description: null, account: null, businessSegment: null, organizationId: null});
    },
    removeAccount(idx) {
      this.$v.form.accounts.$model.splice(idx, 1);
    },
    async doSave(id, data) {
      return id ? await Company.update(id, data) : await Company.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Company.findById(this.id)
          .then(resp => {
            this.form.rfc = resp.data.rfc;
            this.form.name = resp.data.name;
            this.form.email = resp.data.email;
            this.form.type = resp.data.type;
            this.form.accounts = resp.data.accounts;
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {},
      account: undefined,
      form: {
        rfc: null, name: null, email: null, type: null, accounts: []
      },
      editPage: 'COMPANY_EDIT_TITLE'
    }
  },
  validations() {
    const val = {
      form: {
        rfc: {required, minLength: minLength(12), pattern: regex(), maxLength: maxLength(13)},
        name: {required, minLength: minLength(4)},
        email: {required, email, minLength: minLength(4), maxLength: maxLength(300)},
        type: {required},
        accounts: {
          required, minLength: minLength(1), $each: {
            code: {required, minLength: minLength(4), maxLength: maxLength(60)},
            description: {required, minLength: minLength(4), maxLength: maxLength(200)},
            account: {},
            businessSegment: {},
            organizationId: {}
          }
        }
      }
    }

    if(this.form.type !== 'DONOR') {
      val.form.accounts.$each.account = {required, minLength: minLength(4), maxLength: maxLength(200)}
      val.form.accounts.$each.businessSegment = {required, minLength: minLength(4), maxLength: maxLength(200)}
      val.form.accounts.$each.organizationId = {required, minLength: minLength(4), maxLength: maxLength(200)}
    }

    return val;
  }
}
</script>

<style>
.w-10 {
  width: 10% !important;
  max-width: 10% !important;
}
</style>
